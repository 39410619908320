/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createPortal } from 'react-dom';

import ClickOutside from 'Component/ClickOutside';
import {
    PopupComponent as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Pwa/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    render() {
        const {
            mix,
            areOtherOverlaysOpen,
            isSidePopup,
            isFullPopup,
            customClass
        } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
                ref={this.overlayRef}
                block="Popup"
                mods={{
                    isVisible,
                    isSidePopup,
                    isFullPopup,
                    isOverlayPopup: !isSidePopup,
                    isInstant: areOtherOverlaysOpen
                }}
                mix={{ ...mix, mods: { ...mix.mods, isVisible } }}
                className={customClass}
            >
                {customClass === 'newAddToCart' && (
                    <div className="Popup-LeftSide" onClick={this.hidePopUp} />
                )}
                {this.renderContent()}
            </div>,
            document.body
        );
    }

    hidePopUp = () => {
        const {
            hideActiveOverlay, goToPreviousNavigationState, onClose, scrollB
        } = this.props;
        const isVisible = this.getIsVisible();
        if (isVisible) {
            const html = document.documentElement;
            if (scrollB) {
                html.style.scrollBehavior = 'auto';
            }

            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();
            if (scrollB) {
                html.style.scrollBehavior = 'smooth';
            }
        }
    };

    renderContent() {
        const {
            children, contentMix, customClass
        } = this.props;
        const isVisible = this.getIsVisible();
        console.log(children, "children");
        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={this.handleClickOutside}>
                <div block="Popup" elem="Wrapper" mix={contentMix}>
                    <div block="Popup" elem="Body" className={customClass}>
                        <header block="Popup" elem="Header">
                            {this.renderTitle()}
                            {this.renderCloseButton()}
                        </header>
                        {children}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
