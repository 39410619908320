/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */


import {ImageRatio as SourceImageRatio} from "@scandipwa/scandipwa/src/component/Image/Image.type"

export var ImageRatio = {...SourceImageRatio};
