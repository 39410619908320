import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import {
    OverlayComponent as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { ChildrenType, MixType } from '@scandipwa/scandipwa/src/type/Common.type';
import { DeviceType } from '@scandipwa/scandipwa/src/type/Device.type';

import './Overlay.style';

/** @namespace Pwa/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlay {
    // TODO implement
    static propTypes = {
        mix: MixType,
        id: PropTypes.string.isRequired,
        onVisible: PropTypes.func,
        onHide: PropTypes.func,
        activeOverlay: PropTypes.string.isRequired,
        areOtherOverlaysOpen: PropTypes.bool.isRequired,
        isStatic: PropTypes.bool,
        isRenderInPortal: PropTypes.bool,
        children: ChildrenType,
        device: DeviceType.isRequired,
        bypassMobile: PropTypes.bool
    };

    static defaultProps = {
        mix: {},
        children: [],
        onVisible: () => {},
        isStatic: false,
        onHide: () => {},
        isRenderInPortal: true,
        bypassMobile: false
    };

    renderInMobilePortal(content) {
        const {
            bypassMobile, isStatic, isRenderInPortal, isMobile
        } = this.props;

        if (!bypassMobile && !isStatic && isMobile && isRenderInPortal) {
            return createPortal(content, document.body);
        }

        return content;
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            bypassMobile
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ {
                  isVisible, isStatic, bypassMobile, isInstant: areOtherOverlaysOpen
              } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
