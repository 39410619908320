

// import {
//     Device as sourceDevice,
//     DeviceType  as sourceDeviceType
// } from '@scandipwa/scandipwa/src/type/Device.type';

// export const Device = sourceDevice;
// export const DeviceType = sourceDeviceType;


import PropTypes from 'prop-types';

export const DeviceType = PropTypes.shape({
    isMobile: PropTypes.bool.isRequired,
    android: PropTypes.bool.isRequired,
    ios: PropTypes.bool.isRequired,
    blackberry: PropTypes.bool.isRequired,
    opera: PropTypes.bool.isRequired,
    safari: PropTypes.bool.isRequired,
    windows: PropTypes.bool.isRequired,
    standaloneMode: PropTypes.bool,
});
